import { configureStore } from '@reduxjs/toolkit';
import homeReducer from '../redux/reducers/home/homeSlice';
import { api } from '../service/api';
import { Toast } from '../service/toast';

export const store = configureStore({
    reducer: {
        home: homeReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            thunk: {
                extraArgument: { api, Toast },
            },
            serializableCheck: false,
        }),
});
