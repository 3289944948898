import React, { useEffect, useState } from 'react';
import './style.scss'
import Icon from '../../../../shared-components/Icon';
import { colourStyles } from '../../../../shared-components/utils';
import Select from 'react-select'
import { useDispatch } from 'react-redux';
import { CreateProductCategory, CreateProductSubCategory, ProductCategoryList } from '../../../../redux/reducers/home/homeAPI';
import { Toast } from '../../../../service/toast';
import LoaderContext from '../../../../shared-components/LoaderContext';

const AddCategory = (props) => {
    const { categoryOptions } = props

    const [categoryList, setCategoryList] = useState(categoryOptions)
    const [addCategory, setAddCategory] = useState(false)
    const [addSubCategory, setAddSubCategory] = useState(false)
    const [showLoader, setShowLoader] = useState(false)
    const [categoryName, setCategoryName] = useState('')
    const [selectCategory, setSelectCategory] = useState(null)
    const [productCategory, setProductCategory] = useState([])
    const [subCategoryName, setSubCategoryName] = useState('')
    const [selectSubCategory, setSelectSubCategory] = useState(null)
    const [subCategoryList, setSubCategoryList] = useState([])
    const dispatch = useDispatch()

    useEffect(() => {
        if (categoryList?.length > 0) {
            let optionList = []
            categoryList?.map((data) => {
                let obj = { label: data?.name, value: data?.id }
                optionList.push(obj)
            })
            setProductCategory(optionList)
        } else {
            setProductCategory([])
        }
    }, [categoryList])

    useEffect(() => {
        getSubCategory(selectCategory, categoryList)
    }, [selectCategory])

    const getCategory = async () => {
        const response = await dispatch(ProductCategoryList())
        return response?.payload?.data || []
    }

    const getSubCategory = (category, categoryOptions) => {
        if (category && categoryOptions?.length > 0) {
            let filteredList = categoryOptions?.filter((x) => x.id == category?.value)
            let optionList = []
            filteredList?.[0]?.sub_categories?.map((data) => {
                let obj = { label: data?.name, value: data?.id }
                optionList.push(obj)
            })
            setSubCategoryList(optionList)
        } else {
            setSubCategoryList([])
        }
    }

    const addCategoryList = async () => {
        setShowLoader(true)
        let body = {
            "category": categoryName
        }
        const response = await dispatch(CreateProductCategory({
            ...body
        }))
        if (response?.payload?.data) {
            const categoryResponse = await getCategory();
            setCategoryList(categoryResponse)
            setTimeout(() => {
                setAddCategory(false)
                setCategoryName('')
                setShowLoader(false)
            }, 2000)
            Toast({
                type: 'success',
                message: response?.payload?.data?.Message
            })
        }
    }

    const addSubCategoryList = async () => {
        setShowLoader(true)
        let body = {
            "category": selectCategory?.label,
            "sub_category": subCategoryName
        }
        const response = await dispatch(CreateProductSubCategory({
            ...body
        }))
        if (response?.payload?.data) {
            const categoryResponse = await getCategory();
            setCategoryList(categoryResponse)
            await getSubCategory(selectCategory, categoryResponse)
            setTimeout(() => {
                setAddSubCategory(false)
                setSubCategoryName('')
                setShowLoader(false)
            }, 2000)
            Toast({
                type: 'success',
                message: response?.payload?.data?.Message
            })
        }
    }

    const callCategorySelection = (value) => {
        if (selectCategory) {
            if (selectCategory?.value !== value?.value) {
                setSelectSubCategory(null)
            }
        }
        setSelectCategory(value)
    }

    return (
        <>
            <div className='admin-add-category'>

                <div className='form-fields'>
                    <div className='form-label'>
                        Category
                    </div>
                    <div className={`form-input p-0`}>
                        <Select value={selectCategory} className="custom-select" options={productCategory} styles={colourStyles} onChange={callCategorySelection} />
                    </div>
                    {addCategory ?
                        <>
                            <div className='form-label'>
                                Category Name
                            </div>
                            <div className={`form-input`}>
                                <input type='text' value={categoryName} onChange={(e) => setCategoryName(e?.target?.value)} />
                            </div>
                            <div className='form-icon flex-end label-action'>
                                <Icon name="clear" className="action-icon flex-center" onClick={() => { setCategoryName(''); setAddCategory(false) }} />
                                <Icon name="check" className="action-icon flex-center" onClick={() => addCategoryList()} />
                            </div>
                        </>
                        :
                        <div className='flex center add-label' onClick={() => setAddCategory(true)}>
                            <Icon name="plus" />&nbsp;Add Category
                        </div>
                    }
                </div>
                <div className='form-fields'>

                    <div className='form-label'>
                        Sub Category
                    </div>
                    <div className={`form-input p-0`}>
                        <Select value={selectSubCategory} className="custom-select" options={subCategoryList} styles={colourStyles} onChange={setSelectSubCategory} />
                    </div>
                    {addSubCategory ?
                        <>
                            <div className='form-label'>
                                Sub Category Name
                            </div>
                            <div className={`form-input`}>
                                <input type='text' value={subCategoryName} onChange={(e) => setSubCategoryName(e?.target?.value)} />
                            </div>
                            <div className='form-icon flex-end label-action'>
                                <Icon name="clear" className="action-icon flex-center" onClick={() => { setSubCategoryName(''); setAddSubCategory(false) }} />
                                <Icon name="check" className="action-icon flex-center" onClick={() => addSubCategoryList()} />
                            </div>
                        </>
                        :
                        <div className='flex center add-label' onClick={() => setAddSubCategory(true)}>
                            <Icon name="plus" />&nbsp;Add Sub Category
                        </div>
                    }
                </div>
            </div>
            {showLoader &&
                <LoaderContext />
            }
        </>
    )
}

export default AddCategory;