import React, { useEffect, useState } from "react";
import handShake from '../../../../assets/images/icons/handshake.svg'
import quality from '../../../../assets/images/icons/guarantee.svg'
import shipment from '../../../../assets/images/icons/shipment.svg'
import './style.scss'

const ProductDescription = (props) => {
    const { product } = props

    return (
        <>
            <div className="product-description-wrapper">
                <div className="info-title">Description :</div>
                <div className="product-decription">{product?.description}</div>

                <div className="product-detail-icon">
                    <img className="product-icon" src={handShake} />
                    <img className="product-icon" src={quality} />
                    <img className="product-icon" src={shipment} />
                </div>

                <div className="product-decription">
                    Tanjore Painting is a classical south Indian Style form of art.
                    Using 22 carat original gold foil, semi precious stones,Jaipur precious stones, chettinad teak wood frames, this art form comes with vibrant colours to suit the theme of the subject.
                </div>
                <div className="product-decription">
                    Dwarakamaye Tanjore Arts Provide a wide range  of this art form 1600 AD in combination fusion of 2D And 3D Embossed work.
                </div>
                <div className="info-decription">🔔 Paintings  are 100% Handmade.</div>

                <div className="info-decription">🔔 22 carat gold leaves used.</div>

                <div className="info-decription">🔔 Authentic  Jaipur semi precious Stones used.</div>

                <div className="info-decription">🔔 Traditional  and Creative Fusion work Sketches to suit client choice.</div>

                <div className="info-decription">🔔 Chettinad Teak wood frames by default.</div>

                <div className="info-decription">🔔 Imported Fibre frames can also be used.</div>

                <div className="info-decription">🔔 High density saint Gobin glasses for framing.</div>

                <div className="info-decription">🔔 For Export purpose we also use  High quality  unbreakable  Acralyic  Sheets.</div>

                <div className="info-decription">🔔 We specialises in 3D, 2D, and Flat work Embossing.,with a strong baseFoundation.</div>

                <div className="info-decription">🔔 We use Vibrant colour combinations and at the same time subtle veggie colours to suit the Topic of the subject....Fusion  work/ Antique work.</div>

                <div className="info-decription">🔔 Opening of Deities  Eyes are done in Bhramma muhurtham  Time ie early dawn 3.00 to 4.30 a.m. As a divine blessing.</div>


                <div className="info-decription">🔔 Complete  Customer Satisfaction  is our First Priority</div>

            </div>
        </>
    )
}

export default ProductDescription;