import React from 'react';

// interface RouteProps {
//   name: string;
//   className?: string;
//   onClick?: any;
//   onHover?: boolean;
// }

// const Icon: React.FC<RouteProps> = ({ name, className, onClick, onHover, ...other }): JSX.Element => {
//   // return <div className={`icon icon-${name} ${className ? className : ''} ${onHover?'hover-area':'hover-none'}`} onClick={onClick} {...other} />;
//   return <div className={`icon icon-${name} ${className ? className : ''} `} onClick={onClick} {...other} />;
// };


const Icon = (props) => {
  const { name, className, onClick, onHover, ...other } = props
  return (
    <div className={`icon icon-${name} ${className ? className : ''} `} onClick={onClick} {...other} />
  );
}

export default Icon;

