import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';

import './style.scss'

const ProductGallery = (props) => {
    const { tableData } = props
    const [imageList, setImageList] = useState([])
    const [backgroundImage, setBackgroundImage] = useState('')
    const [backgroundPosition, setBackgroundPosition] = useState('100% 100%')

    useEffect(() => {
        if (tableData?.length > 0) {
            let images = []
            tableData?.map((item) => {
                const imageIdList = item?.image.split(";");
                if (imageIdList?.length===1){
                    images?.push(`https://drive.google.com/thumbnail?id=${imageIdList[0]}`)
                }
                if (imageIdList?.length > 1) {
                    imageIdList.map((product) => {
                        images?.push(`https://drive.google.com/thumbnail?id=${product}`)
                    })
                }
            })
            setImageList(images)
            setBackgroundImage(images[0])
        }
    }, [tableData])

    const handleMouseMove = (e) => {
        const { left, top, width, height } = e.target.getBoundingClientRect()
        const x = (e.pageX - left) / width * 100
        const y = (e.pageY - top) / height * 100
        setBackgroundPosition(`${x}% ${y}%`)
    }

    const handleLeave = () => {
        setBackgroundPosition('100% 100%')
    }

    return (
        <>
            <div className="product-gallery-view">
                <div className="product-swiper">
                    <Swiper
                        direction="vertical"
                        spaceBetween={10}
                        slidesPerView={4}
                        freeMode={true}
                        watchSlidesProgress={true}
                        modules={[FreeMode, Navigation, Thumbs]}
                        className={`product-gallery-swiper ${imageList?.length > 3 ? 'more-item' : ''}`}
                        style={{ justifyContent: `${imageList?.length > 3 ? 'unset' : 'center'}` }}
                    >
                        {imageList?.map((image, index) => {
                            return (
                                <SwiperSlide key={index}>
                                    <div className={`product-swiper-list ${image == backgroundImage ? 'active' : ''}`} onClick={() => setBackgroundImage(image)} >
                                        <img src={image} />
                                    </div>
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                </div>
                <div className="product-image">
                    <figure onMouseMove={handleMouseMove} onMouseLeave={handleLeave} style={{ "backgroundImage": `url(${backgroundImage})`, "backgroundPosition": backgroundPosition }}>
                        <img src={backgroundImage} />
                    </figure>
                </div>
            </div>
        </>
    )
}

export default ProductGallery;