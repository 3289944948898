// import { api } from "service/api";
import { setAuthToken } from "../../../service/utilities";
import { AddGalleryProducts, AddProductCategory, AddProductSubCategory, AuthApi, CategoryList, ContactUs, EditGalleryProducts, ProductsList, SearchProductList } from "../../../service/apiVariables";

const { createAsyncThunk } = require("@reduxjs/toolkit");

const namespace = 'home';

export const loginStatus = createAsyncThunk(
  `${namespace}/login`,
  async (payload, { extra: { api, Toast } }) => {

    // THE api - AXIOS COMMON API , TOAST - CUSTOMIZED TOAST which we added in the thunk
    const { loginApi } = AuthApi
    loginApi.body = payload
    try {
      const data = await api({
        ...loginApi,
      })
      if (data?.token) {
        let authToken = null
        let message = data?.message
        let tokenArr = data?.token?.split(' ')
        if (tokenArr?.length > 0) {
          authToken = tokenArr[tokenArr?.length - 1]
        }
        Toast({
          type: 'success',
          message
        })
        setAuthToken(authToken)
      }
      return data
    } catch (error) {
      if (error?.message?.toLowerCase().includes('invalid')) {
        Toast({ type: 'error', message: 'Please enter the valid credentials!' })
      }
    }
    return payload;
  }
)

export const ProductCategoryList = createAsyncThunk(
  `${namespace}/getcategories`,
  async (payload, { extra: { api } }) => {

    const { categoryListApi } = CategoryList
    categoryListApi.body = payload
    try {
      const data = await api({
        ...categoryListApi
      })
      return { data }
    } catch (error) {
      console.log(error)
    }
    return payload;
  }
)

export const GetProductList = createAsyncThunk(
  `${namespace}/products`,
  async (payload, { extra: { api } }) => {
    const { productListApi } = ProductsList
    productListApi.body = payload
    try {
      const data = await api({
        ...productListApi
      })
      return { data }
    } catch (error) {
      console.log(error)
    }
    return payload;
  }
)

export const GetSearchedList = createAsyncThunk(
  `${namespace}/searchproducts`,
  async (payload, { extra: { api } }) => {
    const { searchListApi } = SearchProductList
    searchListApi.body = payload
    try {
      const data = await api({
        ...searchListApi
      })
      return { data }
    } catch (error) {
      console.log(error)
    }
    return payload;
  }
)

export const CreateProductCategory = createAsyncThunk(
  `${namespace}/addcategory`,
  async (payload, { extra: { api } }) => {
    const { addcategoryApi } = AddProductCategory
    addcategoryApi.body = payload
    try {
      const data = await api({
        ...addcategoryApi
      })
      return { data }
    } catch (error) {
      console.log(error)
    }
    return payload;
  }
)

export const CreateProductSubCategory = createAsyncThunk(
  `${namespace}/addsubcategory`,
  async (payload, { extra: { api } }) => {
    const { addsubcategoryApi } = AddProductSubCategory
    addsubcategoryApi.body = payload
    try {
      const data = await api({
        ...addsubcategoryApi
      })
      return { data }
    } catch (error) {
      console.log(error)
    }
    return payload;
  }
)

export const CreateProducts = createAsyncThunk(
  `${namespace}/addproducts`,
  async (payload, { extra: { api } }) => {
    const { addproductsApi } = AddGalleryProducts
    addproductsApi.body = payload
    try {
      const data = await api({
        ...addproductsApi
      })
      return { data }
    } catch (error) {
      console.log(error)
    }
    return payload;
  }
)

export const EditProducts = createAsyncThunk(
  `${namespace}/editproducts`,
  async (payload, { extra: { api } }) => {
    const { editproductsApi } = EditGalleryProducts
    editproductsApi.body = payload
    try {
      const data = await api({
        ...editproductsApi
      })
      return { data }
    } catch (error) {
      console.log(error)
    }
    return payload;
  }
)

export const UserContactUs = createAsyncThunk(
  `${namespace}/contactus`,
  async (payload, { extra: { api } }) => {
    const { contactusApi } = ContactUs
    contactusApi.body = payload
    try {
      const data = await api({
        ...contactusApi
      })
      return { data }
    } catch (error) {
      console.log(error)
    }
    return payload;
  }
)
