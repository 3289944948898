import React, { useEffect, useState } from 'react';
import Logo from '../../assets/images/Dwarakmaye.png'
import './style.scss'
import Icon from '../Icon'
import ContactUs from '../../modal-components/ContactUs';
import { useLocation, useNavigate } from 'react-router-dom';

const Header = () => {
    const [activeIndex, setActiveIndex] = useState('home')
    const [sideBarActive, setSideBarActive] = useState(false)
    const [dropDownActive, setDropDownActive] = useState(false)
    const [viewContact, setViewContact] = useState(false)
    const location = useLocation();
    const navigate = useNavigate();


    const HeaderList = [
        {
            label: 'Home',
            url: '/',
            key: 'home',
        },
        {
            label: 'Admin',
            url: '/login',
            key: 'admin',
        },
        // {
        //     label: 'Category',
        //     key: 'category',
        //     options: [
        //         {
        //             label: 'Page A',
        //             url: '/pageA',
        //             key: 'PageA',
        //         },
        //         {
        //             label: 'Page B Page B',
        //             url: '/pageB',
        //             key: 'PageB',
        //         },
        //     ]
        // },
        {
            label: 'Enquire',
            url: '/contactUs',
            key: 'contactUs',
        },
        {
            label: 'Whatsapp',
            url: '/whatsapp',
            key: 'whatsapp',
        },
    ]

    useEffect(() => {
        if (location && location?.pathname?.split('/')) {
            let params = location?.pathname?.split('/')
            window.scrollTo({ top: 0, behavior: 'smooth' });
            if (params[1] == 'admin' || params[1] == 'home') {
                setActiveIndex(params[1])
            } else {
                setActiveIndex('home')
            }
        }
    }, [location])

    const redirectTo = (path) => {
        navigate(path)
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    
    const navSelection = (key, url, options) => {
        if (key == 'whatsapp') {
            window.open('https://wa.me/8807579104', '_blank')
        } else if (key == 'contactUs') {
            setViewContact(true)
        } else {
            setActiveIndex(key);
            setDropDownActive(options?.length > 0 ? true : false)
            redirectTo(url)
        }
    }
    return (
        <>
            <div className={`gallery-header-container ${sideBarActive ? 'sidebar' : ''}`}>
                <div className='header-logo'>
                    <img src={Logo} alt='Dwarakamaye' />
                </div>
                <div className='hamburger-button'>
                    <Icon name="navbar" onClick={() => setSideBarActive(true)} />
                </div>
                <div className={`header-container ${sideBarActive ? 'sidebar' : ''}`}>
                    <div className='sidebar-close'>
                        <Icon name="clear" onClick={() => setSideBarActive(false)} />
                    </div>
                    {HeaderList?.map(({ label, key, url, options }) => {
                        return (
                            <div className='list-container' key={key}>
                                <div className={`list-header ${activeIndex == key ? 'active' : ''}`} onClick={() => navSelection(key, url, options)}>
                                    <div className="pointer">{label}</div>
                                    {options?.length > 0 &&
                                        <div className={`header-arrow ${dropDownActive && activeIndex == key ? 'active' : ''}`}>
                                            <Icon name="arrow" />
                                        </div>
                                    }
                                </div>
                                {options?.length > 0 && activeIndex == key && dropDownActive &&
                                    <div className={`sub-list-container ${activeIndex == key ? 'active' : ''}`}>
                                        {options?.map(({ label, key }) => {
                                            return <div className={`sub-list-header ${activeIndex == key ? 'active' : ''}`} onClick={() => { setSideBarActive(false); setDropDownActive(false) }}>{label}</div>
                                        })}
                                    </div>
                                }
                            </div>
                        )
                    })}
                </div>
            </div>
            {viewContact &&
                <ContactUs setClosehandler={setViewContact} />
            }
        </>
    )
}

export default Header;