import React, { useEffect, useState } from "react";
import Select from 'react-select'
import { colourStyles, numberWithCommas } from "../../../../shared-components/utils";

import './style.scss'

const ProductPrice = (props) => {

    const { product } = props
    const categoryList = [
        { label: '10 x 8 inches', value: '10 x 8 inches' },
        { label: '12 x 19 inches', value: '12 x 19 inches' },
        { label: '15 x 12 inches', value: '15 x 12 inches' },
        { label: '18 x 15 inches', value: '18 x 15 inches' },
        { label: '20 x 16 inches', value: '20 x 16 inches' },
        { label: '24 x 18 inches', value: '24 x 18 inches' },
        { label: '30 x 24 inches', value: '30 x 24 inches' },
        { label: '32 x 26 inches', value: '32 x 26 inches' },
        { label: '3 x 2 feet', value: '3 x 2 feet' },
    ]

    const [selectCategory, setSelectCategory] = useState('')
    const [quantity, setQuantity] = useState()
    
    return (
        <>
            <div className="product-price-wrapper">
                <div>
                    <div className="flex-between">
                        <div className="product-title">{product?.name}</div>
                        {/* <div className="product-price-mob">{`Rs. ${numberWithCommas(product?.price) || ''}`}</div> */}
                    </div>
                    <div className="product-id">
                        <span>Product ID:</span> &nbsp;
                        <span>{product?.id}</span>
                    </div>
                    <div className="product-info">
                        {product?.description}
                    </div>                  
                    <div className="flex-between">
                        <div className="frame-container">
                            <div className="frame-title">Size with frame</div>
                            <div className="size-frame-selector">
                                <Select className="custom-select" options={categoryList} styles={colourStyles} onChange={setSelectCategory} />
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="product-price">{`Rs. ${numberWithCommas(product?.price) || ''}`}</div> */}
            </div>
        </>
    )
}

export default ProductPrice;
