import React, { useState } from 'react';
import './style.scss'
import Icon from '../Icon';
import BannerTab from '../BannerTab';

const Footer = () => {

    return (
        <>
            <BannerTab />
            <div className='gallery-footer-container'>
                <div className='locate-us'>
                    <div className='our-location'>Our Location</div>

                    <div className='address-navigation'>
                        <div className='address-bar'>
                            <div className='address-header'>Our Gallery Address ...</div>
                            <div className='address '>Dwarakamaye </div>

                            <div className='address '>Plot no. 1032, </div>
                            <div className='address '>42nd street, TVS Colony</div> 
                            <div className='address '> Annanagar west extension,</div>
                            <div className='address '>Chennai, Tamil Nadu - 600101</div>  <br />
                            <div className='address address-line-break'><Icon name="whatsapp" /> 8807579104 </div>
                            <div className='address address-line-break'><Icon name="mail" /> dwarakamaye18@gmail.com</div>
                        </div>
                        <div className='google-pin-container'>
                            <iframe src="https://maps.google.com/maps?q=13.0886097,80.1863966&z=17&output=embed" height="100%" width="100%"></iframe>
                        </div>
                    </div>
                </div >
                <div className='footer-bar'>
                    <div className='copyright'>Copyright © 2023 DWARAKAMAYE - All Rights Reserved.</div>
                    <div className='footer-icons'>
                        <a
                            href="https://wa.me/8807579104"
                            className="whatsapp_float"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Icon name="whatsapp" className="pin-icon" />
                        </a>
                    </div>
                    <div className='powered-by'>Powered By TernTura</div>
                </div>
            </div>
        </>
    )
}

export default Footer;