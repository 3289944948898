import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import SideBarCategory from "../../shared-components/SideBarCategory";
import { mockData } from '../../shared-components/Data'
import ProductList from "./components/ProductList";
import Header from "../../shared-components/Header";
import Footer from "../../shared-components/Footer";
import { GetProductList, GetSearchedList, ProductCategoryList } from "../../redux/reducers/home/homeAPI";
import BannerHeader from "./components/BannerHeader";
import './style.scss'

const Home = (props) => {
    const [tableData, setTableData] = useState([])
    const [productsTable, setProductsTable] = useState([])
    const [category, setCategory] = useState(null)
    const [subCategory, setSubCategory] = useState(null)
    const [categoryList, setCategoryList] = useState('')
    const [searchCriteria, setSearchCriteria] = useState('')
    const [sideBarView, setSideBarView] = useState(false)
    const [sortSelected, setSortSelected] = useState(null)
    const dispatch = useDispatch()

    useEffect(() => {
        getCategory()
    }, [])

    useEffect(() => {
        getSearchData()
    }, [searchCriteria])

    useEffect(() => {
        let table = Object?.assign([], productsTable)
        if (category) {
            let filteredCategory = table?.filter((x) => x.category == category)
            if (subCategory) {
                setTableData(filteredCategory?.filter((x) => x.sub_category == subCategory))
            } else {
                setTableData(filteredCategory)
            }
        } else {
            setTableData(table)
        }
    }, [category, subCategory, productsTable])

    const getCategory = async () => {
        const response = await dispatch(ProductCategoryList())
        setCategoryList(response?.payload?.data)
    }

    const getSearchData = async () => {
        if (searchCriteria?.trim() !== '') {
            const body = {
                search_key: searchCriteria,
            };
            const productList = await dispatch(GetSearchedList({
                ...body
            }));
            setProductsTable(productList?.payload?.data)

        } else {
            const productList = await dispatch(GetProductList())
            setProductsTable(productList?.payload?.data)
        }
    }

    return (
        <>
            <Header />
            <BannerHeader />
            <div className={`dwarakamaye-wrapper ${sideBarView ? 'sidebar' : ''}`}>
                <div className="gallery-container">
                    <div className={`sidebar-container relative ${sideBarView ? 'active' : ''}`}>
                        <SideBarCategory
                            categoryList={categoryList}
                            category={category}
                            setCategory={setCategory}
                            subCategory={subCategory}
                            setSubCategory={setSubCategory}
                            sideBarView={sideBarView}
                            setSideBarView={setSideBarView}
                            sortSelected={sortSelected}
                            setSortSelected={setSortSelected}
                        />
                    </div>
                    <ProductList
                        tableData={tableData}
                        setSideBarView={setSideBarView}
                        searchCriteria={searchCriteria}
                        setSearchCriteria={setSearchCriteria}
                        sortSelected={sortSelected}
                        setSortSelected={setSortSelected}
                    />
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Home;