import React, { useEffect, useState } from 'react';
import Icon from '../Icon'
import secure from '../../assets/images/icons/secure.svg'
import hand from '../../assets/images/icons/hand-holding.svg'
import quality from '../../assets/images/icons/quality.svg'
import shipment from '../../assets/images/icons/delivery-time.svg'

import './style.scss'

const BannerTab = () => {
    const bannerList = [
        { image: secure, title: 'Secured' },
        { image: quality, title: 'Quality Guaranteed' },
        { image: hand, title: '100% Trust Worthy' },
        { image: shipment, title: 'Shipping all over the world' },
    ]
    return (
        <>
            <div className='relative'>
                <div className='banner-wrapper'>
                    <div className='banner-tab'>
                        {bannerList?.map((item, index) => {
                            return (
                                <div className='flex-center' key={index}>
                                    <div className='banner-container'>
                                        <div className='banner-image'>
                                            <img src={item?.image} alt={item?.title} />
                                        </div>
                                        <div className='banner-text'>{item?.title}</div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}

export default BannerTab;