import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from 'react-redux';
import { store } from './helpers';
import { ToastBar, Toaster } from 'react-hot-toast';
import Home from "./pages/Home";
import ProductByID from './pages/ProductByID';
import Login from './pages/Login';
import Admin from './pages/Admin';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import "aos/dist/aos.css";
import './assets/theme/index.scss'
import './style.scss'

function App() {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} exact />
            <Route path="/product" element={<Home />} />
            <Route path="/product/:id" element={<ProductByID />} />
            <Route path="/login" element={<Login />} />
            <Route path="/admin" element={<Admin />} />
          </Routes>
        </BrowserRouter>
        <Toaster
          position="bottom-right"
          gutter={8}
          reverseOrder
          containerClassName="custom-toast"
          containerStyle={{ right: '40px', bottom: '50px', display: "flex", alignItems: "center" }}
        >
          {(t) => (
            <ToastBar toast={t} style={{ padding: 0, overflow: 'hidden' }}>
              {({ icon, message }) => {
                return (
                  <div
                    className={`custom-toast-${t.type} custom-toast-item `}
                  >
                    <div className='flex-center'>
                      {icon}
                      {message}
                    </div>
                  </div>
                );
              }}
            </ToastBar>
          )}
        </Toaster>
      </Provider>
    </React.StrictMode>
  );
}

export default App;
