export const colourStyles = {
    control: (styles) => ({ ...styles, border: 'none', padding: '0', minHeight: 'auto', boxShadow: 'none', backgroundColor: 'white' }),
    valueContainer: (styles) => ({ ...styles, padding: '0 7px' }),
    indicatorContainer: (styles) => ({ ...styles, padding: '0' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        const color = '#ef9c2f';
        return {
            ...styles,
            backgroundColor: isDisabled
                ? undefined
                : isSelected
                    ? color
                    : isFocused
                        ? color + '50'
                        : undefined,
            color: isDisabled
                ? '#ccc'
                : isSelected ? 'white' : 'black',
            cursor: isDisabled ? 'not-allowed' : 'default',
            ':active': {
                ...styles[':active'],
                backgroundColor: !isDisabled
                    ? isSelected
                        ? color
                        : color + '50'
                    : undefined,
            },
            fontFamily: 'Poppins_Regular', fontSize: '14px'
        };
    },
    input: (styles) => ({ ...styles }),
    placeholder: (styles) => ({ ...styles }),
    singleValue: (styles, { data }) => ({ ...styles, fontFamily: 'Poppins_Regular', fontSize: '14px' }),
};


export const toDecimal = (value, decimalLength = 1, forIndicativeNav = false) => {
    if (value === NaN ||
        value === 'NaN' ||
        typeof value === 'null' ||
        typeof value === 'undefined' ||
        !value) return 0;
    const array = value.toString().split('.');
    let out;
    if (array.length === 2) {
        out = `${array[0]}.${array[1].substr(0, decimalLength)}`
        if (forIndicativeNav) {
            out = Number(value).toFixed(decimalLength)
        } else {
            out = Number(`${array[0]}.${array[1].substr(0, decimalLength)}`).toFixed(decimalLength)
        }
    } else {
        out = Number(parseFloat(value).toFixed(decimalLength))
    }
    return !isNaN(out) ? Number(out) : 0;
}

export function numberWithCommas(x) {
    if (x == 0) return 0;
    if (!x || isNaN(x)) return x;
    x = x % 1 === 0 ? x : toDecimal(x, 2);
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const Alphabet_AtoZ = 'A to Z'
export const Alphabet_ZtoA = 'Z to A'
export const Price_High_to_Low = 'Price - High to Low'
export const Price_Low_to_High = 'Price - Low to High'
export const PRODUCT_SORT_LIST = [
    Alphabet_AtoZ, Alphabet_ZtoA
    // ,Price_High_to_Low, Price_Low_to_High
]

export const checkEmailField = (field) => {
    var regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    return field?.match(regex) ? true : false
}

export const checkPhoneNumber = (field) => {
    var regex = /([0-9\s\-]{7,})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;
    return field?.match(regex) ? true : false
}