import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
import useMediaQuery from "../../../../shared-components/utils/useMediaQuery";
  import './style.scss'
  
  const BannerHeader = (props) => {
    const isTab = useMediaQuery('(max-width: 768px)');
    const imageList = [
        'https://c4.wallpaperflare.com/wallpaper/264/641/305/god-lord-krishna-wallpaper-preview.jpg',
        'https://wallpapers.com/images/hd/hindu-monkey-god-sdxrb1hvqzh6b30u.jpg',
        'https://c4.wallpaperflare.com/wallpaper/162/297/191/lord-ganesha-vinayaka-ganapati-statue-wallpaper-preview.jpg',
        'https://c4.wallpaperflare.com/wallpaper/264/641/305/god-lord-krishna-wallpaper-preview.jpg',
        'https://wallpapers.com/images/hd/hindu-monkey-god-sdxrb1hvqzh6b30u.jpg',
        'https://c4.wallpaperflare.com/wallpaper/162/297/191/lord-ganesha-vinayaka-ganapati-statue-wallpaper-preview.jpg',
    ]
    return (
        <>
            <div className="banner-header-container">
                <Swiper
                    spaceBetween={0}
                    slidesPerView={isTab ? 1 : 2}
                    watchSlidesProgress={true}
                    loop={true}
                    autoplay={{
                        delay: 2000,
                        pauseOnMouseEnter: true,
                        disableOnInteraction: false
                    }}
                    infinite="true"
                    speed={500}
                    modules={[Navigation, Autoplay]}
                    className={`banner-header-swiper`}
                >
                    {imageList?.map((image, index) => {
                        return (
                            <SwiperSlide key={index}>
                                <div className="banner-image">
                                    <img src={image} />
                                </div>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
            </div>
        </>
    )
}

export default BannerHeader;