import {
  axiosInstance,
  cancelTokenSource,
  statusHelper,
  getServiceUrl,
  getAuthToken
} from './utilities';

export const api = async function ({
  method = 'get',
  api: apiURL,
  body,
  status = false,
  baseURL = 'user',
  token = null,
  configObj = {}
}) {
  const tempConfigObj = { ...configObj };

  if (cancelTokenSource.token)
    tempConfigObj.cancelToken = cancelTokenSource.token;

  const getValidToken = () => {
    if (token || getAuthToken()) {
        axiosInstance.defaults.headers.common.Authorization = `Bearer ${token || getAuthToken()
        }`;
    }
  };

  return await new Promise((resolve, reject) => {
    getValidToken();
    axiosInstance[method](
      `${getServiceUrl(baseURL)}${apiURL}`,
      body || '',
      tempConfigObj
    )
      .then((data) => {
        resolve(statusHelper(status, data));
      })
      .catch((error) => {
        try {
          if (error.response) {
            reject(statusHelper(status, error.response));
          } else {
            reject(error);
          }
        } catch (err) {
          reject(err);
        }
      });
  });
};
