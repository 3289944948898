export const AuthApi = {
  loginApi: {
    api: '/login',
    baseURL: 'baseURL',
    method: 'post'
  }
};
export const CategoryList = {
  categoryListApi: {
    api: '/getcategories',
    baseURL: 'getCategories',
    method: 'get'
  }
};
export const ProductsList = {
  productListApi: {
    api: '/products',
    baseURL: 'products',
    method: 'get'
  }
};
export const SearchProductList = {
  searchListApi: {
    api: '/searchproducts?',
    baseURL: 'searchproducts',
    method: 'get',
    get api() {
      return '/searchproducts?search_key=' + this.body.search_key;
    },
  }
};

export const AddProductCategory = {
  addcategoryApi: {
    api: '/addcategory',
    baseURL: 'addcategory',
    method: 'post'
  }
};

export const AddProductSubCategory = {
  addsubcategoryApi: {
    api: '/addsubcategory',
    baseURL: 'addsubcategory',
    method: 'post'
  }
};

export const AddGalleryProducts = {
  addproductsApi: {
    api: '/addproducts',
    baseURL: 'addproducts',
    method: 'post'
  }
};

export const EditGalleryProducts = {
  editproductsApi: {
    api: '/editproducts',
    baseURL: 'editproducts',
    method: 'post'
  }
};

export const ContactUs = {
  contactusApi: {
    api: '/contactus',
    baseURL: 'contactus',
    method: 'post'
  }
};