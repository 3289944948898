import React, { useEffect, useState } from 'react';
import Select from 'react-select'
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';
import { colourStyles } from '../../../../shared-components/utils';
import Icon from '../../../../shared-components/Icon'
import './style.scss'
import { useDispatch } from 'react-redux';
import { CreateProducts, EditProducts } from '../../../../redux/reducers/home/homeAPI';
import { Toast } from '../../../../service/toast';
import LoaderContext from '../../../../shared-components/LoaderContext';
import useMediaQuery from '../../../../shared-components/utils/useMediaQuery';
import { useRef } from 'react';

const AddProducts = (props) => {
    const { categoryList, editProduct, setEditProduct, setActiveTab, Products } = props

    const [productName, setProductName] = useState('')
    const [selectCategory, setSelectCategory] = useState(null)
    const [selectSubCategory, setSelectSubCategory] = useState(null)
    const [productCategory, setProductCategory] = useState(null)
    const [subCategoryList, setSubCategoryList] = useState(null)
    const [productPrice, setProductPrice] = useState('')
    const [textComment, setTextComment] = useState('')
    const [replacedFiles, setReplacedFiles] = useState('')
    const [fileUploads, setFileUploads] = useState([])
    const [showLoader, setShowLoader] = useState(false)
    const [fileName, setFileName] = useState('')
    const [preview, setPreview] = useState()
    const [disabled, setDisabled] = useState(true)
    const dispatch = useDispatch();
    const swiperRef = useRef(null);
    const isMobile = useMediaQuery('(max-width: 550px)');

    useEffect(() => {
        if (editProduct && Object?.entries(editProduct)?.length > 0) {
            setProductName(editProduct?.name)
            setProductPrice(editProduct?.price)
            setTextComment(editProduct?.description)
            setFileName(editProduct?.image_name)
            if (editProduct?.image) {
                let imageList = editProduct?.image?.split(';')
                setPreview(`https://drive.google.com/thumbnail?id=${imageList[0]}`)
                let uploadList = []
                imageList?.map((file) => {
                    let obj = {
                        imageName: '',
                        imageUrl: file,
                        preview: `https://drive.google.com/thumbnail?id=${file}`,
                        baseFormat: false
                    }
                    uploadList?.push(obj)
                })
                setFileUploads(uploadList)
            }
            let category = null
            let subCategory = null
            categoryList?.map((data, idx) => {
                if (data?.name == editProduct?.category) {
                    category = {
                        label: data?.name, value: data?.id
                    }
                    let filteredCategory = data?.sub_categories?.filter((x) => x?.name == editProduct?.sub_category)
                    if (filteredCategory?.length > 0) {
                        subCategory = {
                            label: filteredCategory[0]?.name, value: filteredCategory[0]?.id
                        }
                    }
                }
            })
            if (category?.value) {
                setSelectCategory(category)
            }
            if (subCategory?.value) {
                setSelectSubCategory(subCategory)
            }
        }
    }, [editProduct])

    useEffect(() => {
        if (productName?.trim() !== '' && selectCategory && selectSubCategory && preview && fileUploads?.length > 0 && productPrice && textComment?.trim()) {
            setDisabled(false)
        } else {
            setDisabled(true)
        }
    }, [productName, selectCategory, selectSubCategory, productPrice, preview, textComment, fileUploads])

    useEffect(() => {
        if (!(editProduct && Object?.entries(editProduct)?.length > 0)) {
            setSelectSubCategory(null)
        }
    }, [selectCategory])

    useEffect(() => {
        if (categoryList?.length > 0) {
            let optionList = []
            categoryList?.map((data) => {
                let obj = { label: data?.name, value: data?.id }
                optionList.push(obj)
            })
            setProductCategory(optionList)
        } else {
            setProductCategory([])
        }
    }, [categoryList])

    useEffect(() => {
        if (selectCategory && categoryList?.length > 0) {
            let filteredList = categoryList?.filter((x) => x.id == selectCategory?.value)
            let optionList = []
            filteredList?.[0]?.sub_categories?.map((data) => {
                let obj = { label: data?.name, value: data?.id }
                optionList.push(obj)
            })
            setSubCategoryList(optionList)
        } else {
            setSubCategoryList([])
        }
    }, [selectCategory])

    useEffect(() => {
        if (fileUploads?.length > 0) {
            setPreview(fileUploads[0]?.preview)
        } else {
            setPreview(null)
        }
    }, [fileUploads])

    const clearAction = () => {
        setProductName('')
        setSelectCategory(null)
        setSelectSubCategory(null)
        setProductPrice('')
        setTextComment('')
        setEditProduct(null)
        clearImage()
    }

    const getBase64 = async (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => {
                resolve(reader.result)
            }
            reader.onerror = reject
        })
    }

    const handleFileUpload = async (product, replaceView = false) => {
        const productFile = Object.assign([], fileUploads)
        Object.entries(product)?.map((fileList) => {
            const file = fileList[1]
            const { type } = file;
            const validType = ['image/jpg', 'image/jpeg', 'image/png']
            if (validType?.includes(type)) {
                getBase64(file)
                    .then(res => {
                        var passingWord = "base64,";
                        var position = res.indexOf(passingWord);
                        if (position !== -1) {
                            var newText = res.substring(position + passingWord.length);
                            let obj = {
                                imageName: file?.name,
                                imageUrl: newText,
                                preview: URL.createObjectURL(file),
                                baseFormat: true
                            }
                            productFile?.push(obj)
                            if (replaceView && editProduct && Object?.entries(editProduct)?.length > 0) {
                                getReplacedData(preview)
                                setFileUploads(productFile?.filter((x) => x?.preview !== preview))
                                goToLastIndex(productFile?.filter((x) => x?.preview !== preview)?.length)
                            } else {
                                setFileUploads(productFile)
                                goToLastIndex(productFile?.length)
                            }
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
            } else {
                Toast({
                    type: 'error',
                    message: 'Invalid File Type'
                })
            }
        })
    };


    const saveChanges = async () => {
        setShowLoader(true)
        let body = {
            "name": productName,
            "category": selectCategory?.label,
            "sub_category": selectSubCategory?.label,
            "description": textComment,
            "price": productPrice,
        }
        if (editProduct && editProduct?.id && Object?.entries(editProduct)?.length > 0) {
            body["product_id"] = editProduct?.id
            if (fileUploads) {
                let imageList = []
                fileUploads?.map((product) => {
                    if (product?.baseFormat) {
                        let obj = {
                            image_name: product?.imageName,
                            image: product?.imageUrl
                        }
                        imageList.push(obj)
                    }
                })
                let obj = {}
                if (imageList?.length > 0) {
                    obj["new_images"] = imageList
                }
                if (replacedFiles?.trim !== '' && replacedFiles?.length > 0) {
                    obj["deleted_images"] = replacedFiles
                }
                body["image"] = obj
            }
            const response = await dispatch(EditProducts({ ...body }))
            if (response?.payload?.data) {
                setShowLoader(false)
                Toast({
                    type: 'success',
                    message: response?.payload?.data?.message
                })
            }
            clearAction()
            setActiveTab(Products)
        } else {
            if (fileUploads?.length > 0) {
                let imageList = []
                fileUploads?.map((product) => {
                    if (product?.baseFormat) {
                        let obj = {
                            image_name: product?.imageName,
                            image: product?.imageUrl
                        }
                        imageList.push(obj)
                    }
                })
                body["image_data"] = imageList
            }
            const response = await dispatch(CreateProducts({ ...body }))
            if (response?.payload?.data) {
                setShowLoader(false)
                Toast({
                    type: 'success',
                    message: response?.payload?.data?.message
                })
            }
            clearAction()
            setActiveTab(Products)
        }
    }

    const goToLastIndex = (uploadCount) => {
        if (swiperRef.current && uploadCount > 2 && uploadCount > swiperRef.current.swiper.slides.length) {
            const lastIndex = swiperRef.current.swiper.slides.length + 1;
            setTimeout(() => {
                swiperRef.current.swiper.slideTo(lastIndex);
            },500)
        }
    };

    const getReplacedData = (previewImage) => {
        if (editProduct && Object?.entries(editProduct)?.length > 0 && previewImage) {
            let image = previewImage?.split('id=')
            let deletedRecords = Object.assign([], replacedFiles.split(';'))
            deletedRecords.push(image[1])
            setReplacedFiles(deletedRecords?.filter((x) => x !== '')?.join(';'))
        }
    }

    const clearItem = (index, previewImage) => {
        getReplacedData(previewImage)
        const productFile = Object.assign([], fileUploads)
        setFileUploads(productFile?.filter((x, idx) => idx !== index))
    }

    const clearImage = () => {
        setFileUploads([])
        setFileName('')
        setPreview(null)
    }

    const callCategorySelection = (value) => {
        if (selectCategory) {
            if (selectCategory?.value !== value?.value) {
                setSelectSubCategory(null)
            }
        }
        setSelectCategory(value)
    }

    return (
        <>
            <div className='admin-input-wrapper'>
                <div className='form-upload'>

                    <div class="fileinputs">
                        <input
                            type="file"
                            class="file"
                            title=" "
                            multiple
                            name={`image-upload`}
                            onChange={(e) => {
                                handleFileUpload(e?.target?.files, true)
                            }}
                        />
                        <div className='fakeFile'>
                            <div className='upload-preview'>
                                {fileUploads?.length > 0 ?
                                    <img src={preview} />
                                    :
                                    <div>Upload here....</div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className='product-swiper-wrapper'>
                    {fileUploads?.length > 0 &&
                        <>
                            <Swiper
                                ref={swiperRef}
                                spaceBetween={0}
                                slidesPerView={isMobile ? 2 : 3}
                                freeMode={true}
                                watchSlidesProgress={true}
                                modules={[FreeMode, Navigation, Thumbs]}
                                className={`product-upload-list`}
                                style={{ justifyContent: `${fileUploads?.length > 2 ? 'unset' : 'center'}` }}
                            >
                                {fileUploads?.map((product, idx) => {
                                    return (
                                        <SwiperSlide key={idx} style={{ margin: 'auto', width: 'fit-content' }}>
                                            <div className='product-banner'>
                                                <img src={product?.preview} onClick={() => setPreview(product?.preview)} />
                                                <div className='clear-image' onClick={() => clearItem(idx, product?.preview)}>
                                                    <Icon name="clear" />
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    )
                                })}
                            </Swiper>
                            <div className='add-product-image flex-center'>
                                <Icon name="plus" />
                                <input
                                    type="file"
                                    class="file"
                                    title=" "
                                    multiple
                                    name={`image-upload`}
                                    onChange={(e) => {
                                        handleFileUpload(e?.target?.files)
                                    }}
                                />
                            </div>
                        </>
                    }
                </div>
                <div className='form-fields'>
                    <div className='form-label'>
                        Product Name
                    </div>
                    <div className='form-input'>
                        <input type='text' value={productName} onChange={(e) => setProductName(e?.target?.value)} />
                    </div>
                </div>
                <div className='form-fields'>
                    <div className='form-label'>
                        Category
                    </div>
                    <div className={`form-input p-0`}>
                        <Select value={selectCategory} className="custom-select" options={productCategory} styles={colourStyles} onChange={callCategorySelection} />
                    </div>

                </div>
                <div className='form-fields'>
                    <div className='form-label'>
                        Sub Category
                    </div>
                    <div className={`form-input p-0`}>
                        <Select value={selectSubCategory} className="custom-select" options={subCategoryList} styles={colourStyles} onChange={setSelectSubCategory} />
                    </div>

                </div>
                <div className='form-fields'>
                    <div className='form-label'>
                        Product Price
                    </div>
                    <div className='form-input p-0'>
                        <div className='input-wrapper'>
                            <div className='form-icon'>
                                <Icon name="inr" className="flex-center" />
                            </div>
                            <input type='number' value={productPrice} onChange={(e) => setProductPrice(e?.target?.value)} />
                        </div>
                    </div>
                </div>
                <div className='form-fields'>
                    <div className='form-label'>
                        Product Description
                    </div>
                    <div className={`form-input`}>
                        <textarea rows="5" placeholder=" " width="100%" maxLength="500" value={textComment} onChange={(e) => setTextComment(e?.target?.value)} />
                    </div>

                </div>
                <div className='form-action'>
                    <button className='cancel-button' onClick={() => clearAction()}>Cancel</button>
                    <button className={`submit-button ${disabled ? 'disabled' : ''}`} onClick={() => !disabled ? saveChanges() : null}>Submit</button>
                </div>
            </div>
            {showLoader &&
                <LoaderContext />
            }
        </>
    )
}

export default AddProducts;