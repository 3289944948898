import React, { useEffect, useState } from 'react';
import AOS from "aos";
import Header from '../../shared-components/Header';
import Footer from '../../shared-components/Footer';
import AddProducts from './components/AddProducts';
import { GetProductList, GetSearchedList, ProductCategoryList } from '../../redux/reducers/home/homeAPI';
import { useDispatch } from 'react-redux';
import { mockData } from '../../shared-components/Data';
import './style.scss'
import AdminProducts from './components/AdminProducts';
import AdminTab from './components/AdminTab';
import AddCategory from './components/AddCategory';
import SideBarCategory from '../../shared-components/SideBarCategory';
import Icon from '../../shared-components/Icon';
import { getAuthToken, logout } from '../../service/utilities';
import { useNavigate } from 'react-router-dom';

const Admin = () => {
    const Products = 'Products'
    const Add_Products = 'Add Products'
    const Add_Category_Sub_Category = 'Add Category / Sub-category'
    const tabOptions = [Products, Add_Products, Add_Category_Sub_Category]
    const [tableData, setTableData] = useState([])
    const [productsTable, setProductsTable] = useState([])
    const [category, setCategory] = useState(null)
    const [activeTab, setActiveTab] = useState(Products)
    const [subCategory, setSubCategory] = useState(null)
    const [categoryList, setCategoryList] = useState('')
    const [searchCriteria, setSearchCriteria] = useState('')
    const [sortSelected, setSortSelected] = useState(null)
    const [sideBarView, setSideBarView] = useState(null)
    const [editProduct, setEditProduct] = useState(null)
    const authToken = getAuthToken();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!authToken) {
            navigate('/')
        }
    }, [authToken])

    useEffect(() => {
        AOS.init({
            duration: 800
        });
    }, []);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [activeTab])

    useEffect(() => {
        getCategory()
    }, [])

    useEffect(() => {
        if(activeTab == Products) {
            getSearchData()
        }
    }, [searchCriteria, activeTab])

    useEffect(() => {
        let table = Object?.assign([], productsTable)
        if (category) {
            let filteredCategory = table?.filter((x) => x.category == category)
            if (subCategory) {
                setTableData(filteredCategory?.filter((x) => x.sub_category == subCategory))
            } else {
                setTableData(filteredCategory)
            }
        } else {
            setTableData(table)
        }
    }, [category, subCategory, productsTable])

    const getCategory = async () => {
        const response = await dispatch(ProductCategoryList())
        setCategoryList(response?.payload?.data)
    }

    const getSearchData = async () => {
        if (searchCriteria?.trim() !== '') {
            const body = {
                search_key: searchCriteria,
            };
            const productList = await dispatch(GetSearchedList({
                ...body
            }));
            setProductsTable(productList?.payload?.data)

        } else {
            const productList = await dispatch(GetProductList())
            setProductsTable(productList?.payload?.data)
        }
    }

    return (
        <>
            <Header />
            <div className={`dwarakamaye-admin-wrapper`}>
                <div className='logout-container' onClick={() => logout()}>
                    <Icon name="power" />
                    <div>Logout</div>
                </div>
                <div>
                    <AdminTab activeTab={activeTab} setActiveTab={setActiveTab} setEditProduct={setEditProduct} tabOptions={tabOptions} />
                </div>
                <div className='tab-switch-wrapper'>
                    {activeTab == Products ?
                        <AdminProducts
                            tableData={tableData}
                            searchCriteria={searchCriteria}
                            setSearchCriteria={setSearchCriteria}
                            // categoryList={categoryList}
                            sortSelected={sortSelected}
                            setSortSelected={sortSelected}
                            sideBarView={sideBarView}
                            setSideBarView={setSideBarView}
                            Add_Products={Add_Products}
                            setActiveTab={setActiveTab}
                            setEditProduct={setEditProduct}
                        />
                        : activeTab == Add_Products ?
                            <AddProducts
                                editProduct={editProduct}
                                setEditProduct={setEditProduct}
                                categoryList={categoryList}
                                setActiveTab={setActiveTab}
                                Products={Products}

                            /> :
                            activeTab == Add_Category_Sub_Category ?
                                <AddCategory
                                    categoryOptions={categoryList}
                                    setCategoryList={setCategoryList}
                                /> : null
                    }
                </div>
                {sideBarView &&
                    <div className={`sidebar-container relative ${sideBarView ? 'active' : ''}`}>
                        <SideBarCategory
                            categoryList={categoryList}
                            category={category}
                            setCategory={setCategory}
                            subCategory={subCategory}
                            setSubCategory={setSubCategory}
                            sideBarView={sideBarView}
                            setSideBarView={setSideBarView}
                            sortSelected={sortSelected}
                            setSortSelected={setSortSelected}
                            admin={true}
                        />
                    </div>
                }
            </div>
            <Footer />
        </>
    )
}

export default Admin;