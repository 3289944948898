import React, { useEffect, useState } from "react";
import './style.scss'
import { Alphabet_AtoZ, Alphabet_ZtoA, PRODUCT_SORT_LIST, Price_High_to_Low, Price_Low_to_High, numberWithCommas } from "../../../../shared-components/utils";
import Icon from "../../../../shared-components/Icon";

const AdminProducts = (props) => {
    const { tableData, searchCriteria, setSearchCriteria, sortSelected, setSortSelected, sideBarView, setSideBarView, setActiveTab, setEditProduct, Add_Products } = props

    const [productData, setProductData] = useState(null)
    const [sortActive, setSortActive] = useState(false)

    useEffect(() => {
        let data;
        let table = Object.assign([], tableData);
        let parse = (s) =>
            typeof s === "string" ? parseInt(s.replace(/,/g, "")) : s;
        if (sortSelected == Alphabet_AtoZ) {
            data = table?.sort((a, b) => a['name']?.toString().localeCompare(b['name']?.toString()))
        } else if (sortSelected == Alphabet_ZtoA) {
            data = table?.sort((a, b) => b['name']?.toString().localeCompare(a['name']?.toString()))
        } else if (sortSelected == Price_High_to_Low) {
            data = table?.sort((a, b) => parse(b['price']) - parse(a['price']))
        } else if (sortSelected == Price_Low_to_High) {
            data = table?.sort((a, b) => parse(a['price']) - parse(b['price']))
        } else {
            data = tableData
        }
        setProductData(data)
    }, [sortSelected, tableData])

    const sortOrder = (filter) => {
        setSortSelected(filter)
        setSortActive(false)
    }

    const editAction = (data) => {
        setEditProduct(data)
        setActiveTab(Add_Products)
    }

    return (
        <>
            <div className="w-100 relative">
                <div className='dwarakamaye-admin-header'>
                    <div className="flex-between product-header">
                        <div className="wrapper-title" data-aos="zoom-in">All Products</div>
                        <div className="product-action-header flex center">
                            <button className="pointer flex-center" onClick={() => setSideBarView(true)}>Catergory</button>
                            <div className="relative sort-action">
                                <button className="pointer flex-center" onClick={() => setSortActive(!sortActive)}>Sort by <Icon name="arrow" /></button>
                                {sortActive &&
                                    <div className="sort-by-container">
                                        {PRODUCT_SORT_LIST?.map((data, index) => {
                                            return (
                                                <div key={index} className={`sort-category ${sortSelected == data ? 'active' : ''}`} onClick={() => sortOrder(data)}>{data}</div>
                                            )
                                        })}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="search-wrapper">
                        <div className='input-wrapper'>
                            <div className='form-icon flex-center'>
                                <Icon name="search" />
                            </div>
                            <input type='text' value={searchCriteria} onChange={(e) => setSearchCriteria(e?.target?.value)} placeholder="Search..." />
                        </div>
                    </div>
                </div>
                <div className="admin-product-wrapper">
                    <div className="admin-product-gallery">
                        {productData?.map((data, idx) => {
                            return (
                                <div className="product-wrapper relative flex-center">
                                    <div className="product-image relative">
                                        <img className="" src={data?.image ? `https://drive.google.com/thumbnail?id=${data?.image?.split(';')[0]}` : null} alt={data?.name} />                                        
                                        <div className="edit-product" onClick={() => editAction(data)}>
                                            <Icon name="pencil" />
                                        </div>
                                    </div>
                                    <div className="flex-center product-footer-tag">
                                        <div className="product-title">{data?.name}</div>
                                        {/* <div className="product-price">Rs. {numberWithCommas(data?.price)}</div> */}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>

            </div>
        </>
    )
}

export default AdminProducts;