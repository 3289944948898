import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Icon from '../../shared-components/Icon';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ProductGallery from "./components/ProductGallery";
import ProductPrice from "./components/ProductPrice";
import ProductDescription from "./components/ProductDescription";
import Header from "../../shared-components/Header";
import Footer from "../../shared-components/Footer";
import { GetProductList } from "../../redux/reducers/home/homeAPI";

import './style.scss'

const ProductByID = (props) => {

    const navigate = useNavigate();
    const location = useLocation()
    const params = useParams()
    const dispatch = useDispatch()
    const [tableData, setTableData] = useState([])

    useEffect(() => {
        if (params?.id) {
            getProductDetails(params?.id)
        }
    }, [location, params])

    const getProductDetails = async (id) => {
        const productList = await dispatch(GetProductList())
        let tableList = productList?.payload?.data
        setTableData(tableList?.filter((x) => x.id == id))
    }
    const redirectTo = (path) => {
        navigate(path)
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }


    return (
        <>
            <Header />
            <div className="product-wrapper-cart">
                <div className="product-breadcrumbs" onClick={() => redirectTo('/')}>
                    <Icon name="arrow" />
                    <div className="navigation">Back</div>
                </div>
                <div className="product-gallery-info">
                    <ProductGallery tableData={tableData} />
                    <ProductPrice product={tableData[0]} />
                </div>
                <div className="product-breif-details">
                    <ProductDescription product={tableData[0]} />
                </div>
            </div>
            <Footer />
        </>
    )
}

export default ProductByID;