import React, { useEffect, useState } from 'react';
import './style.scss'

const AdminTab = (props) => {

    const { tabOptions, activeTab, setActiveTab, setEditProduct } = props
    return (
        <>
            <div className='admin-tab-wrapper'>
                {tabOptions?.map((item) => {
                    return (
                        <div className={`tab-name ${activeTab == item ? 'active' : ''}`} key={item} onClick={() => {setActiveTab(item);setEditProduct(null)}}>{item}</div>
                    )
                })}
            </div>
        </>
    )
}

export default AdminTab;