import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Logo from '../../assets/images/Dwarakmaye.png'
import Icon from '../../shared-components/Icon'
import { loginStatus } from '../../redux/reducers/home/homeAPI';
import './style.scss'
import { Toast } from '../../service/toast';
import LoaderContext from '../../shared-components/LoaderContext';

const Login = () => {
    const [userName, setUserName] = useState('')
    const [password, setPassword] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const [disabled, setDisabled] = useState(true)
    const [showLoader, setShowLoader] = useState(false)
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (userName?.trim() !== '' && password?.trim() !== '') {
            setDisabled(false)
        } else {
            setDisabled(true)
        }
    }, [userName, password])

    const redirectTo = (path) => {
        navigate(path)
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const userLogin = async () => {
        setShowLoader(true)
        let values = {
            "email": userName,
            "password": password
        }
        const response = await dispatch(loginStatus({
            ...values
        }))
        if (response?.payload?.user_id) {
            setTimeout(() => {
                setShowLoader(false)
                redirectTo('/admin')
            }, 2000)
        } else {
            setTimeout(() => {
                setShowLoader(false)
            }, 2000)
        }
    }

    return (
        <>
            <div className='dwarakamaye-admin-login'>
                <div className='admin-logo flex-center'>
                    <img src={Logo} alt='Dwarakamaye' />
                </div>
                <div className='admin-login-container'>
                    <div className='login-container'>
                        <div className='form-fields'>
                            <div className='form-label'>Username</div>
                            <div className='input-wrapper'>
                                <input type='text' value={userName} onChange={(e) => setUserName(e?.target?.value)} />
                                <div>|</div>
                                <div className='form-icon flex-center'>
                                    <Icon name="user" />
                                </div>
                            </div>
                        </div>
                        <div className='form-fields'>
                            <div className='form-label'>Password</div>
                            <div className='input-wrapper password'>
                                <input type={`${showPassword ? 'text' : 'password'}`} value={password} onChange={(e) => setPassword(e?.target?.value)} />
                                <div>|</div>
                                <div className='form-icon flex-center'>
                                    {showPassword ?
                                        <Icon name="eye" onClick={() => setShowPassword(!showPassword)} />
                                        :
                                        <Icon name="eye-hidden" onClick={() => setShowPassword(!showPassword)} />
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='login-action flex-center'>
                            <button className={`login-button ${disabled ? 'disabled' : ''}`} onClick={() => !disabled ? userLogin() : null}>Login</button>
                        </div>
                    </div>
                </div>
            </div>
            {showLoader &&
                <LoaderContext message={'Logging in...'} />
            }
        </>
    )
}

export default Login;