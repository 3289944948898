import React, { useEffect } from "react";
import Icon from "../Icon";
import AOS from 'aos';
import './style.scss';
import { PRODUCT_SORT_LIST } from "../utils";

const SideBarCategory = (props) => {
    useEffect(() => {
        AOS.init({
            duration: 800
        });
    }, []);

    const { category, setCategory, sideBarView, setSideBarView, categoryList, subCategory, setSubCategory, sortSelected, setSortSelected, admin = false } = props

    const selectCategory = (data) => {
        if (category && category == data?.name) {
            setCategory(null)
            setSubCategory(null)
        } else {
            setCategory(data?.name)
            setSubCategory(null)
        }
    }

    return (
        <>
            <div className={`product-sidebar-list ${admin ? 'admin-sidebar' : ''}`}>
                <div className="product-sidebar">
                    {!admin &&
                        <div className="sidebar-sort-wrapper">
                            <div className="section-title" data-aos="fade-left">Sort By</div>
                            <div className="sidebar-sort-container">
                                {PRODUCT_SORT_LIST?.map((data, index) => {
                                    return (
                                        <div key={index} className={`sort-category ${sortSelected == data ? 'active' : ''}`} onClick={() => setSortSelected(data)}>{data}</div>
                                    )
                                })}
                            </div>
                        </div>
                    }
                    <div className="section-title" data-aos="fade-left">Category</div>
                    <Icon name="clear" className="sidebar-clear" onClick={() => setSideBarView(false)} />
                    <div className="sidebar-category">
                        {categoryList?.length > 0 && categoryList.map((data, index) => {
                            return (
                                <div key={index}>
                                    <div className={`flex-between product-category ${category && category == data?.name ? 'active' : ''}`}>
                                        <div key={data?.id} className={`category-name`} onClick={() => selectCategory(data)}>{data?.name}</div>
                                        {data?.sub_categories?.length > 0 &&
                                            <Icon name="arrow" className="flex-center" />
                                        }
                                    </div>
                                    {data?.sub_categories?.length > 0 && category && category == data?.name &&
                                        data?.sub_categories?.map((item) => {
                                            return (
                                                <div key={item?.id} className={`sub-category-name ${subCategory == item?.name ? 'active' : ''}`} onClick={() => setSubCategory(item?.name)}>{item?.name}</div>
                                            )
                                        })
                                    }
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}

export default SideBarCategory;