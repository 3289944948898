import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import './style.scss'
import AOS from "aos";
import Icon from "../../../../shared-components/Icon";
import { Alphabet_AtoZ, Alphabet_ZtoA, Price_High_to_Low, Price_Low_to_High, numberWithCommas, PRODUCT_SORT_LIST } from "../../../../shared-components/utils";

const ProductList = (props) => {
    const { tableData, setSideBarView, searchCriteria, setSearchCriteria, sortSelected, setSortSelected } = props

    const navigate = useNavigate();
    const [productData, setProductData] = useState(null)
    const [sortActive, setSortActive] = useState(false)

    useEffect(() => {
        AOS.init({
            duration: 800
        });
    }, []);

    useEffect(() => {
        let data;
        let table = Object.assign([], tableData);
        let parse = (s) =>
            typeof s === "string" ? parseInt(s.replace(/,/g, "")) : s;
        if (sortSelected == Alphabet_AtoZ) {
            data = table?.sort((a, b) => a['name']?.toString().localeCompare(b['name']?.toString()))
        } else if (sortSelected == Alphabet_ZtoA) {
            data = table?.sort((a, b) => b['name']?.toString().localeCompare(a['name']?.toString()))
        } else if (sortSelected == Price_High_to_Low) {
            data = table?.sort((a, b) => parse(b['price']) - parse(a['price']))
        } else if (sortSelected == Price_Low_to_High) {
            data = table?.sort((a, b) => parse(a['price']) - parse(b['price']))
        } else {
            data = tableData
        }
        setProductData(data)
    }, [sortSelected, tableData])

    const sortOrder = (filter) => {
        setSortSelected(filter)
        setSortActive(false)
    }

    const redirectTo = (path) => {
        navigate(path)
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    return (
        <>
            <div className="">
                <div className="dwarakamaye-products">
                    <div className="product-wrapper">
                        <div className="flex-between product-header">
                            <div className="wrapper-title" data-aos="zoom-in">All Products</div>
                            <div className="product-action-header flex center">
                                <button className="pointer flex-center" onClick={() => setSideBarView(true)}>Catergory</button>
                                <div className="relative sort-action">
                                    <button className="pointer flex-center" onClick={() => setSortActive(!sortActive)}>Sort by <Icon name="arrow" /></button>
                                    {sortActive &&
                                        <div className="sort-by-container">
                                            {PRODUCT_SORT_LIST?.map((data, index) => {
                                                return (
                                                    <div key={index} className={`sort-category ${sortSelected == data ? 'active' : ''}`} onClick={() => sortOrder(data)}>{data}</div>
                                                )
                                            })}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="search-wrapper">
                            <div className='input-wrapper'>
                                <div className='form-icon flex-center'>
                                    <Icon name="search" />
                                </div>
                                <input type='text' value={searchCriteria} onChange={(e) => setSearchCriteria(e?.target?.value)} placeholder="Search..." />
                            </div>
                        </div>
                        <div className={`product-list ${productData?.length > 2 ? '' : 'short-table'}`}>
                            {productData?.length > 0 ?
                                productData?.map((data, index) => {
                                    return (
                                        <div className="product-info" onClick={() => redirectTo(`product/${data?.id}`)} key={index}>
                                            <div className="flex-center product-image">
                                                <img className="" src={data?.image ? `https://drive.google.com/thumbnail?id=${data?.image.split(";")[0]}` : null} alt={data?.name}/>
                                            </div>
                                            <div className="product-title">{data?.name}</div>
                                            <div className="product-footer">
                                                <div />
                                                {/* <div className="product-price">
                                                    Rs. {numberWithCommas(data?.price)}
                                                </div> */}
                                                <div className="read-more">More</div>
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                <div className="empty-records flex-center">No Records!..</div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProductList;