import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import AOS from 'aos';
import { UserContactUs } from "../../redux/reducers/home/homeAPI";
import { Toast } from "../../service/toast";
import Icon from "../../shared-components/Icon";
import { checkEmailField, checkPhoneNumber } from "../../shared-components/utils";
import LoaderContext from "../../shared-components/LoaderContext";
import './style.scss'

function ContactUs(props) {
    const { setClosehandler } = props
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNum, setPhoneNum] = useState('');
    const [email, setEmail] = useState('');
    const [comment, setComment] = useState('');
    const [showLoader, setShowLoader] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const dispatch = useDispatch()
    const params = useParams()

    useEffect(() => {
        AOS.init({
            duration: 500
        });
    }, []);

    useEffect(() => {
        if (firstName?.trim() !== '' && lastName?.trim() !== '' && email?.trim() !== '') {
            setDisabled(false)
        } else {
            setDisabled(true)
        }
    }, [firstName, lastName, email, phoneNum, comment])

    const sumbitForm = async () => {
        if (checkEmailField(email) && checkPhoneNumber(phoneNum)) {
            setShowLoader(true)
            const body = {
                "first_name": firstName,
                "last_name": lastName,
                "email": email,
                "phone": phoneNum,
                "comments": comment
            }
            if(params?.id) {
                body['product_id'] = params?.id
            }
            const response = await dispatch(UserContactUs({
                ...body
            }))
            if (response?.payload?.data?.Message) {
                setTimeout(() => {
                    setShowLoader(false)
                    Toast({ type: 'success', message: response?.payload?.data?.Message })
                    clearForm()
                    setClosehandler(false)
                }, 2000)
            } else {
                setTimeout(() => {
                    setShowLoader(false)
                    Toast({ type: 'error', message: "Some Error Occured!" })
                }, 2000)
            }

        } else if (!checkEmailField(email)) {
            Toast({ type: 'error', message: "Please provide a valid email" })
        } else if (!checkPhoneNumber(phoneNum)) {
            Toast({ type: 'error', message: "Please provide a valid phone number" })
        }
    }
    const clearForm = () => {
        setFirstName('')
        setLastName('')
        setEmail('')
        setPhoneNum('')
        setComment('')
    }

    return (
        <>
            <div className="modalOverlay">
                <div className="overlay--bg" onClick={() => setClosehandler(false)}></div>
                <div className="overlay--box contact-us" data-aos="fade-up">
                    <div className="overlay--container">
                        <div className="overlay-close" onClick={() => setClosehandler(false)}>
                            <Icon name="clear" />
                        </div>
                        <div className="overlay-title">Contact Us</div>
                        <div className="container-wrapper contact-us">
                            <div className="contactform form-inline">
                                <div className="flex">
                                    <div className="w-50">
                                        <label className="form-title">First Name</label>
                                        <input type="text" required size="32" value={firstName} onChange={(e) => setFirstName(e.target?.value)} />
                                    </div>
                                    <div className="w-50">
                                        <label className="form-title">Last Name</label>
                                        <input type="text" required size="32"
                                            value={lastName} onChange={(e) => setLastName(e.target?.value)} />
                                    </div>
                                </div>
                                <div className="flex">
                                    <div className="w-50">
                                        <label className="form-title">Phone</label>
                                        <input type="tel" size="32" value={phoneNum} onChange={(e) => setPhoneNum(e.target?.value)} />
                                    </div>
                                    <div className="w-50">
                                        <label className="form-title">Email</label>
                                        <input type="email" required size="32"
                                            value={email} onChange={(e) => setEmail(e.target?.value)} />
                                    </div>
                                </div>
                                <div className="">
                                    <div className="w-100"> <span className="charNum">{`${500 - comment?.length}/500 Characters Remaining`}</span>
                                        <label
                                            className="form-title">Comments</label>
                                        <textarea rows="5"
                                            placeholder=" " width="100%" maxLength="500"
                                            value={comment} onChange={(e) => setComment(e.target?.value)} ></textarea>
                                    </div>
                                    <div className="flex">
                                        <div className="w-100">
                                            <div className="form-actions flex-center">
                                                <button className={`submit-button ${disabled ? 'disabled' : ''}`}
                                                    onClick={() => !disabled && sumbitForm()}>Submit</button>
                                                <button className="cancel-button" onClick={() => {clearForm();setClosehandler(false)}}>Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showLoader &&
                <LoaderContext />
            }
        </>
    );
}

export default ContactUs;
