import React from "react";
import './style.scss'
const LoaderContext = (props) => {
    const { message = "Processing Request..." } = props

    return (
        <div className="loader-context-wrapper">
            <div className="loader-bg"></div>
            <div className="loader-body">
                <div className="loader-text">
                    {message}
                </div>
            </div>
        </div>
    );
}

export default LoaderContext;